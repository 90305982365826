// data.js

export const DefinicionesArquetipos = {
  arquetipos: [
    {
      nombre: "People",
      definicion:
        "Es propia de organizaciones gestionadas como corporaciones familiares y enfocadas en el bienestar y desarrollo de su capital humano, el mantenimiento de un clima laboral positivo y unas buenas relaciones de trabajo. La cohesión y la satisfacción del personal priman sobre objetivos financieros y de mercado.",
    },
    {
      nombre: "Innovation",
      definicion:
        "Es propia de organizaciones altamente creativas, dinámicas o innovadoras, que suelen caracterizarse por asumir riesgos y promover la creación de ideas y conocimiento, la I+D, el atrevimiento, la autonomía, y son capaces de responder más rápidamente a los cambios que se producen en su entorno.",
    },
    {
      nombre: "Goals",
      definicion:
        "Es propia de aquellas organizaciones que siguen un modelo de gestión basado en metas racionales o gestión por objetivos. Enfatiza la consecución de objetivos estratégicos, tales como la ganancia de cuota de mercado, el cumplimiento de ratios financieros, la mejora de la reputación y el éxito competitivo.",
    },
    {
      nombre: "Norms",
      definicion:
        "Es propia de aquellas organizaciones gestionadas según principios burocráticos. Se caracterizan por estar administradas en base a normas estrictas, procedimientos, manuales, y por mantener una noción muy estricta de la jerarquía. Ponen el énfasis en la seguridad, la predictibilidad, el orden y el control.",
    },
    {
      nombre: "Digital",
      definicion:
        "Es propia de organizaciones que poseen una gran apertura al cambio y una mentalidad analítica. Se gestionan en base a la cooperación y disposición de sus colaboradores para compartir conocimientos. Ponen el énfasis en la adaptación al cliente mediante el aprendizaje continuo y la tolerancia al fallo.",
    },
    {
      nombre: "ESG",
      definicion:
        "Es propia de organizaciones que destacan por su compromiso para con la responsabilidad social y la sostenibilidad. Se esfuerzan para lograr unos objetivos sociales que van más allá del rendimiento y la maximización de las ganancias y buscan generar un impacto positivo en el medio ambiente y la sociedad.",
    },
  ],
};

export const companyData = {
  1: {
    Nombre: "Acciona",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C4D0BAQHfncojVoQuAQ/company-logo_200_200/company-logo_200_200/0/1677686168409/acciona_logo?e=1733961600&v=beta&t=RTYJfGXhSB_t5B1HMfVGoO5V54AF6Y6rPoXn9cZpH80",
    Industria: "Energía",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Integridad",
      "Transparencia",
      "Excelencia",
      "Sostenibilidad",
      "Estabilidad",
      "Innovación",
      "Bienestar",
      "Generosidad",
    ],
    ArquetiposPuntuación: {
      People: 15,
      Innovation: 15,
      Norms: 15,
      Goals: 15,
      Digital: 10,
      ESG: 30,
    },
  },
  2: {
    Nombre: "Acerinox",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C560BAQFIKm2wTzibog/company-logo_200_200/company-logo_200_200/0/1630575629394/acerinox_s_a__logo?e=1733961600&v=beta&t=rimD7-0TQ5FfOeaijNFvHwRNBZxkuINns9oXDhtVp88",
    Industria: "Minería",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Bienestar",
      "Sostenibilidad",
      "Trabajo en equipo",
      "Integridad",
      "Compromiso",
      "Perseverancia",
      "Excelencia",
      "Innovación",
      "Eficiencia",
    ],
    ArquetiposPuntuación: {
      People: 20,
      Innovation: 10,
      Norms: 20,
      Goals: 10,
      Digital: 10,
      ESG: 30,
    },
  },
  3: {
    Nombre: "ACS",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQFXGXq_KPpSdg/company-logo_200_200/company-logo_200_200/0/1712749921862/grupo_acs_logo?e=1733961600&v=beta&t=OXjLSKxhBWaaimjQtOD35ot_0Ky8l6-4nQN51N8Ck0o",
    Industria: "Construcción",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Orientación a resultados",
      "Eficiencia",
      "Compromiso",
      "Integridad",
      "Excelencia",
      "Confianza",
    ],
    ArquetiposPuntuación: {
      People: 25,
      Innovation: 10,
      Norms: 15,
      Goals: 25,
      Digital: 10,
      ESG: 15,
    },
  },
  4: {
    Nombre: "Aena",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQHISEhwQFxEBg/company-logo_200_200/company-logo_200_200/0/1719819079541/aena_logo?e=1733961600&v=beta&t=iS6z4PQaa3X3tNlrERrjqF6FGUycAUE6bAN2y4aFTiY",
    Industria: "Aeronáutica",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Integridad",
      "Responsabilidad",
      "Innovación",
      "Bienestar",
      "Excelencia",
      "Eficiencia",
      "Sostenibilidad",
    ],
    ArquetiposPuntuación: {
      People: 10,
      Innovation: 15,
      Norms: 20,
      Goals: 15,
      Digital: 10,
      ESG: 30,
    },
  },
  5: {
    Nombre: "Amadeus",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQG4fET5G1jx8w/company-logo_200_200/company-logo_200_200/0/1698053556150/amadeus_logo?e=1733961600&v=beta&t=XNmX5E8yylBNfA0LNs--MqsAbQ4c-eq6lZog0KNuisg",
    Industria: "Consultoría",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Empatía",
      "Compromiso",
      "Trabajo en equipo",
      "Diversidad",
      "Responsabilidad",
      "Orientación al aprendizaje",
      "Excelencia",
      "Innovación",
      "Apertura al cambio",
    ],
    ArquetiposPuntuación: {
      People: 22,
      Innovation: 12,
      Norms: 12,
      Goals: 12,
      Digital: 30,
      ESG: 12,
    },
  },
  6: {
    Nombre: "ArcelorMittal",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C4E0BAQHsvvyIuBK8Lw/company-logo_200_200/company-logo_200_200/0/1661852655574/arcelormittal_logo?e=1733961600&v=beta&t=3j9FZm04efzoebTgsgiHrI2Rw3g4PDEHILmOQnbtiHw",
    Industria: "Minería",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Bienestar",
      "Responsabilidad",
      "Sostenibilidad",
      "Excelencia",
      "Liderazgo",
      "Innovación",
      "Diversidad",
    ],
    ArquetiposPuntuación: {
      People: 10,
      Innovation: 10,
      Norms: 15,
      Goals: 25,
      Digital: 10,
      ESG: 30,
    },
  },
  7: {
    Nombre: "Banco Sabadell",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C560BAQG-PMWNCWDUDg/company-logo_200_200/company-logo_200_200/0/1631328939910?e=1733961600&v=beta&t=4lS84NjQX9uS1tNNBk7IBAm0q861CQSvdD3lN6aYAfY",
    Industria: "Banca",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Compromiso",
      "Apertura al cambio",
      "Excelencia",
      "Eficiencia",
      "Generosidad",
      "Transparencia",
    ],
    ArquetiposPuntuación: {
      People: 30,
      Innovation: 10,
      Norms: 15,
      Goals: 15,
      Digital: 15,
      ESG: 15,
    },
  },
  8: {
    Nombre: "Banco Santander",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQGVRxhl9ugIOA/company-logo_200_200/company-logo_200_200/0/1719830522728/banco_santander_logo?e=1733961600&v=beta&t=4ZJJqXG1nBoAo3dB1nfs2VeBVuMVweCoYBIWEhKvXiI",
    Industria: "Banca",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Transparencia",
      "Eficiencia",
      "Empatía",
      "Generosidad",
      "Respeto",
    ],
    ArquetiposPuntuación: {
      People: 15,
      Innovation: 10,
      Norms: 20,
      Goals: 10,
      Digital: 15,
      ESG: 30,
    },
  },
  9: {
    Nombre: "Bankinter",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C4E0BAQFk43Kc9tHB6Q/company-logo_200_200/company-logo_200_200/0/1630576899747/bankinter_logo?e=1733961600&v=beta&t=Q0TlG7p9s14CqN142kyrVmoLrl7W2ZhG_BL9svcjGNs",
    Industria: "Banca",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Dinamismo",
      "Eficiencia",
      "Innovación",
      "Atrevimiento",
      "Integridad",
      "Creatividad",
      "Bienestar",
      "Generosidad",
      "Trabajo en equipo",
    ],
    ArquetiposPuntuación: {
      People: 20,
      Innovation: 30,
      Norms: 10,
      Goals: 10,
      Digital: 10,
      ESG: 20,
    },
  },
  10: {
    Nombre: "BBVA",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQF6uIMbar31kA/company-logo_200_200/company-logo_200_200/0/1720426435192/bbva_logo?e=1733961600&v=beta&t=o6BvoPSN7B6Pe3x8EmWCWAnw2LzUQ27exE6aJNEmmd4",
    Industria: "Banca",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Empatía",
      "Bienestar",
      "Integridad",
      "Innovación",
      "Ambición",
      "Apertura al cambio",
      "Excelencia",
      "Compromiso",
      "Responsabilidad",
      "Confianza",
      "Orientación a resultados",
      "Trabajo en equipo",
    ],
    ArquetiposPuntuación: {
      People: 25,
      Innovation: 10,
      Norms: 10,
      Goals: 25,
      Digital: 10,
      ESG: 20,
    },
  },
  11: {
    Nombre: "CaixaBank",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C560BAQELgROu-FKRmw/company-logo_200_200/company-logo_200_200/0/1631340429726?e=1733961600&v=beta&t=fpGO3IHWOzUQ-pBgR-J7-WqZIRDLWf2EpcmHmLYeqas",
    Industria: "Banca",
    País: "España",
    Verificada: false,
    ValorCFAM: ["Excelencia", "Confianza", "Responsabilidad", "Compromiso"],
    ArquetiposPuntuación: {
      People: 30,
      Innovation: 10,
      Norms: 20,
      Goals: 20,
      Digital: 10,
      ESG: 10,
    },
  },
  12: {
    Nombre: "Cellnex",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQFUH56zMnOdIA/company-logo_200_200/company-logo_200_200/0/1715682310440/cellnextelecom_logo?e=1733961600&v=beta&t=-aEqlvboGOUc4GPkAkJSrScnpY1-XHNobBVhCfImgSo",
    Industria: "Telecomunicaciones",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Compromiso",
      "Innovación",
      "Atrevimiento",
      "Integridad",
      "Sostenibilidad",
      "Diversidad",
      "Generosidad",
    ],
    ArquetiposPuntuación: {
      People: 20,
      Innovation: 20,
      Norms: 10,
      Goals: 10,
      Digital: 10,
      ESG: 30,
    },
  },
  13: {
    Nombre: "Colonial",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C560BAQHFvu1wSyOfFQ/company-logo_200_200/company-logo_200_200/0/1630615146747/inmobiliaria_colonial_logo?e=1733961600&v=beta&t=n8f4NlCmN_kjuNy8OcZaiMos5UDXRURCGDAFtW3afxs",
    Industria: "Inmobiliaria",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Excelencia",
      "Eficiencia",
      "Responsabilidad",
      "Liderazgo",
      "Compromiso",
      "Formalidad",
      "Transparencia",
    ],
    ArquetiposPuntuación: {
      People: 15,
      Innovation: 10,
      Norms: 30,
      Goals: 20,
      Digital: 10,
      ESG: 15,
    },
  },
  14: {
    Nombre: "Enagás",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQE0Lpkly7I3rg/company-logo_200_200/company-logo_200_200/0/1719819235771/enagas_logo?e=1733961600&v=beta&t=vraZqPLcgula_BkzO_c1gbiUr7locf54UXUg01JeTjY",
    Industria: "Energía",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Estabilidad",
      "Responsabilidad",
      "Sostenibilidad",
      "Orientación a resultados",
      "Innovación",
      "Adaptación al cambio",
      "Excelencia",
      "Bienestar",
      "Diversidad",
      "Generosidad",
      "Trabajo en equipo",
    ],
    ArquetiposPuntuación: {
      People: 17,
      Innovation: 10,
      Norms: 17,
      Goals: 17,
      Digital: 10,
      ESG: 29,
    },
  },
  15: {
    Nombre: "Endesa",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C4D0BAQHFEE5mdCOWBA/company-logo_200_200/company-logo_200_200/0/1630570417038/endesa_logo?e=1733961600&v=beta&t=_H46QQcZJ2ZY7yQTIBgVkPFYj3HbrnZPPRCGM13EaPI",
    Industria: "Energía",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Responsabilidad",
      "Compromiso",
      "Innovación",
      "Apertura al cambio",
      "Tolerancia al fallo",
      "Confianza",
      "Transparencia",
      "Integridad",
      "Dinamismo",
      "Orientación a resultados",
    ],
    ArquetiposPuntuación: {
      People: 17,
      Innovation: 17,
      Norms: 10,
      Goals: 10,
      Digital: 29,
      ESG: 17,
    },
  },
  16: {
    Nombre: "Ferrovial",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQH2FEKyhFTXKw/company-logo_200_200/company-logo_200_200/0/1711442746058/ferrovial_logo?e=1733961600&v=beta&t=sfyB5-TFCeqEb5XU6ui21LcM763Z6ih3CvI1-xXW7Fg",
    Industria: "Construcción",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Respeto",
      "Bienestar",
      "Responsabilidad",
      "Excelencia",
      "Eficiencia",
      "Enfoque analítico",
      "Integridad",
      "Cooperación",
      "Trabajo en equipo",
      "Autonomía",
      "Diversidad",
      "Innovación",
      "Apertura al cambio",
      "Tolerancia al fallo",
    ],
    ArquetiposPuntuación: {
      People: 10,
      Innovation: 15,
      Norms: 15,
      Goals: 10,
      Digital: 25,
      ESG: 25,
    },
  },
  17: {
    Nombre: "Fluidra",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C560BAQEnf0-ifmaPuA/company-logo_200_200/company-logo_200_200/0/1631439545653/fluidra_logo?e=1733961600&v=beta&t=eKetGP1tFA_97BRARzkjABoW_sf6tb6orxrx7XmUd14",
    Industria: "Manufacturera",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Empatía",
      "Cooperación",
      "Excelencia",
      "Innovación",
      "Responsabilidad",
      "Orientación al aprendizaje",
      "Apertura al cambio",
      "Trabajo en equipo",
      "Diversidad",
      "Integridad",
      "Confianza",
      "Transparencia",
      "Compromiso",
      "Orientación a resultados",
    ],
    ArquetiposPuntuación: {
      People: 20,
      Innovation: 10,
      Norms: 10,
      Goals: 15,
      Digital: 25,
      ESG: 20,
    },
  },
  18: {
    Nombre: "Grifols",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQGBRhY2i97-HA/company-logo_200_200/company-logo_200_200/0/1664530276433/grifols_logo?e=1733961600&v=beta&t=R3LlxpvhVIlC701PLK_Kv16NuF6mbfudJUjFznUYzf8",
    Industria: "Farmacéutica",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Confianza",
      "Bienestar",
      "Responsabilidad",
      "Perseverancia",
      "Compromiso",
      "Excelencia",
      "Trabajo en equipo",
      "Innovación",
      "Integridad",
      "Formalidad",
      "Transparencia",
    ],
    ArquetiposPuntuación: {
      People: 20,
      Innovation: 15,
      Norms: 20,
      Goals: 15,
      Digital: 10,
      ESG: 20,
    },
  },
  19: {
    Nombre: "IAG",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C4E0BAQEB5PDic7VBXw/company-logo_200_200/company-logo_200_200/0/1669722016417/international_airlines_group_iag__logo?e=1733961600&v=beta&t=j58DD0DZodCcfvVEKeiJRBVD_lS9ecWiKXGAkrtEtgc",
    Industria: "Aeronáutica",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Innovación",
      "Pragmatismo",
      "Ambición",
      "Perseverancia",
      "Trabajo en equipo",
      "Responsabilidad",
      "Bienestar",
      "Diversidad",
      "Generosidad",
      "Sostenibilidad",
    ],
    ArquetiposPuntuación: {
      People: 20,
      Innovation: 10,
      Norms: 20,
      Goals: 20,
      Digital: 10,
      ESG: 20,
    },
  },
  20: {
    Nombre: "Iberdrola",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4E0BAQGp9ZcOjqHnmg/company-logo_200_200/company-logo_200_200/0/1719988562242/iberdrola_logo?e=1733961600&v=beta&t=TcdotvlEfIjsnfWfrOfWJeKNaUNxEQ975w0dhrKz-CE",
    Industria: "Energía",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Sostenibilidad",
      "Responsabilidad",
      "Integridad",
      "Transparencia",
      "Bienestar",
      "Diversidad",
      "Cooperación",
      "Generosidad",
      "Cercanía",
      "Innovación",
      "Eficiencia",
      "Dinamismo",
      "Adaptación al cambio",
    ],
    ArquetiposPuntuación: {
      People: 20,
      Innovation: 15,
      Norms: 15,
      Goals: 10,
      Digital: 10,
      ESG: 30,
    },
  },
  21: {
    Nombre: "Inditex",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C4E0BAQFFiw9GETHCuw/company-logo_200_200/company-logo_200_200/0/1657632770204/inditex_logo?e=1733961600&v=beta&t=OC3j7H5s7Pn0jhb6MX9PXCKRCohEvM6WtGx8v8EJxfE",
    Industria: "Retail",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Innovación",
      "Atrevimiento",
      "Ambición",
      "Perseverancia",
      "Diversidad",
      "Generosidad",
      "Trabajo en equipo",
      "Cercanía",
      "Integridad",
      "Transparencia",
    ],
    ArquetiposPuntuación: {
      People: 25,
      Innovation: 20,
      Norms: 10,
      Goals: 10,
      Digital: 10,
      ESG: 25,
    },
  },
  22: {
    Nombre: "Indra",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C4D0BAQE_l86n4N73QQ/company-logo_200_200/company-logo_200_200/0/1646898677492/indra_logo?e=1733961600&v=beta&t=slQqt0xuTzYIZ5p86Upk6AUcuP1fglaDQbQ19kciFJQ",
    Industria: "Consultoría",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Liderazgo",
      "Empatía",
      "Cercanía",
      "Orientación a resultados",
      "Confianza",
      "Excelencia",
    ],
    ArquetiposPuntuación: {
      People: 25,
      Innovation: 10,
      Norms: 10,
      Goals: 10,
      Digital: 15,
      ESG: 30,
    },
  },
  23: {
    Nombre: "Logista",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C4E0BAQFuxCGMtmlayw/company-logo_200_200/company-logo_200_200/0/1656411490723/logista_logo?e=1733961600&v=beta&t=ZKXb8vIgdLgbRK-zRjCQdIAeKJ2TMTsftENO3aErxes",
    Industria: "Transporte",
    País: "España",
    Verificada: false,
    ValorCFAM: ["Confianza", "Dinamismo", "Apertura al cambio", "Empatía"],
    ArquetiposPuntuación: {
      People: 22,
      Innovation: 22,
      Norms: 10,
      Goals: 10,
      Digital: 26,
      ESG: 10,
    },
  },
  24: {
    Nombre: "Mapfre",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQHGd27N_sW7cQ/company-logo_200_200/company-logo_200_200/0/1698056445305/mapfre_logo?e=1733961600&v=beta&t=59H7s91ZaGAzTFTN6ZG9xQJfpxIX9YTHMfQT35zSiaA",
    Industria: "Seguros",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Empatía",
      "Cooperación",
      "Estabilidad",
      "Confianza",
      "Innovación",
      "Integridad",
      "Trabajo en equipo",
      "Diversidad",
    ],
    ArquetiposPuntuación: {
      People: 25,
      Innovation: 15,
      Norms: 15,
      Goals: 10,
      Digital: 15,
      ESG: 20,
    },
  },
  25: {
    Nombre: "Meliá",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C4D0BAQEn4y_jcvZmcQ/company-logo_200_200/company-logo_200_200/0/1630561752272/melia_hotels_international_logo?e=1733961600&v=beta&t=SqnyQ7MsiVZDcoNTdzN9bGYyVR5ZaY0h0MtkG6Gomyw",
    Industria: "Hostelería",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Excelencia",
      "Orientación a resultados",
      "Empatía",
      "Cooperación",
      "Innovación",
      "Cercanía",
      "Trabajo en equipo",
      "Integridad",
      "Generosidad",
      "Sostenibilidad",
    ],
    ArquetiposPuntuación: {
      People: 30,
      Innovation: 10,
      Norms: 10,
      Goals: 20,
      Digital: 10,
      ESG: 20,
    },
  },
  26: {
    Nombre: "Merlin Properties",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C4E0BAQGALyWf6GY8dQ/company-logo_200_200/company-logo_200_200/0/1653649496588/merlinproperties_logo?e=1733961600&v=beta&t=v5dX27JDXYAOl5ter5gox-pkQlK9NqlCYA3dtMRahsg",
    Industria: "Inmobiliaria",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Integridad",
      "Responsabilidad",
      "Orientación a resultados",
      "Sostenibilidad",
      "Respeto",
    ],
    ArquetiposPuntuación: {
      People: 10,
      Innovation: 10,
      Norms: 20,
      Goals: 20,
      Digital: 10,
      ESG: 30,
    },
  },
  27: {
    Nombre: "Naturgy",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQGa-Y9Jcg6uCw/company-logo_200_200/company-logo_200_200/0/1697785540479/naturgy_logo?e=1733961600&v=beta&t=jX_MwvCbM6aC78j0IM6UooH6n9tOWSxY8mrzetkpSvc",
    Industria: "Energía",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Innovación",
      "Adaptación al cambio",
      "Tolerancia al riesgo",
      "Excelencia",
      "Liderazgo",
      "Orientación a resultados",
      "Cercanía",
      "Transparencia",
      "Confianza",
      "Compromiso",
      "Sostenibilidad",
      "Respeto",
      "Responsabilidad",
    ],
    ArquetiposPuntuación: {
      People: 20,
      Innovation: 18,
      Norms: 11,
      Goals: 20,
      Digital: 11,
      ESG: 20,
    },
  },
  28: {
    Nombre: "Redeia",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQHDHV4h_ZxoDw/company-logo_200_200/company-logo_200_200/0/1720419604259/redeia_logo?e=1733961600&v=beta&t=zyblGlqEKvYqA-e_ZA7iR3lwwSOTMy0n1oz3rCSdlgs",
    Industria: "Energía",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Respeto",
      "Integridad",
      "Sostenibilidad",
      "Innovación",
      "Eficiencia",
      "Bienestar",
      "Generosidad",
      "Diversidad",
      "Trabajo en equipo",
    ],
    ArquetiposPuntuación: {
      People: 20,
      Innovation: 15,
      Norms: 15,
      Goals: 10,
      Digital: 10,
      ESG: 30,
    },
  },
  29: {
    Nombre: "Repsol",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C4E0BAQHUQ9FrNU2B3Q/company-logo_200_200/company-logo_200_200/0/1630614665749/repsol_logo?e=1733961600&v=beta&t=3rOpS-rpuxFaAWMrDB5CieW7UTGU_qwCKeTIYZpz788",
    Industria: "Energía",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Eficiencia",
      "Dinamismo",
      "Adaptación al cambio",
      "Flexibilidad",
      "Respeto",
      "Confianza",
      "Diversidad",
      "Responsabilidad",
      "Sostenibilidad",
      "Innovación",
      "Apertura al cambio",
      "Orientación a resultados",
    ],
    ArquetiposPuntuación: {
      People: 10,
      Innovation: 15,
      Norms: 15,
      Goals: 10,
      Digital: 25,
      ESG: 25,
    },
  },
  30: {
    Nombre: "Rovi",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQGoQuZfw_oCrw/company-logo_200_200/company-logo_200_200/0/1695722417018/pharmaceutical_laboratories_rovi_logo?e=1733961600&v=beta&t=LFXK9CosjZdlJFjCaxB2w-3ia_yDkaFBp7ILBkpAUwc",
    Industria: "Farmacéutica",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Integridad",
      "Responsabilidad",
      "Transparencia",
      "Compromiso",
      "Colaboración",
      "Generosidad",
      "Trabajo en equipo",
      "Eficiencia",
      "Enfoque analítico",
      "Excelencia",
      "Orientación a resultados",
      "Bienestar",
      "Cercanía",
      "Innovación",
      "Dinamismo",
      "Creatividad",
      "Respeto",
    ],
    ArquetiposPuntuación: {
      People: 25,
      Innovation: 20,
      Norms: 10,
      Goals: 10,
      Digital: 10,
      ESG: 25,
    },
  },
  31: {
    Nombre: "Sacyr",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQHuIGVc7EHmkQ/company-logo_200_200/company-logo_200_200/0/1710067635622/sacyr_logo?e=1733961600&v=beta&t=V08hdqUDYsgagyiVktkHFAQr_7HCOh-SCRB61JTFRlA",
    Industria: "Construcción",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Excelencia",
      "Innovación",
      "Trabajo en equipo",
      "Generosidad",
      "Diversidad",
      "Compromiso",
      "Responsabilidad",
      "Sostenibilidad",
      "Empatía",
      "Respeto",
    ],
    ArquetiposPuntuación: {
      People: 30,
      Innovation: 10,
      Norms: 10,
      Goals: 10,
      Digital: 10,
      ESG: 30,
    },
  },
  32: {
    Nombre: "Solaria",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C4E0BAQEX7Uyt5Q4ODQ/company-logo_200_200/company-logo_200_200/0/1634638830962/solaria_energ_a_y_medio_ambiente_logo?e=1733961600&v=beta&t=LzYFoM5Q-hKkb4MJzdB8Mt0F7lWJUbBBA8s26v9aZhM",
    Industria: "Energía",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Sostenibilidad",
      "Liderazgo",
      "Integridad",
      "Innovación",
      "Excelencia",
      "Trabajo en equipo",
    ],
    ArquetiposPuntuación: {
      People: 15,
      Innovation: 15,
      Norms: 10,
      Goals: 25,
      Digital: 10,
      ESG: 25,
    },
  },
  33: {
    Nombre: "Telefónica",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQHysPrm0CIRlg/company-logo_200_200/company-logo_200_200/0/1719918350378/telefonica_logo?e=1733961600&v=beta&t=o2oiKSHxUjxQ8uZooUkkxCThYSQ6m1agBGAOtXggOgI",
    Industria: "Telecomunicaciones",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Colaboración",
      "Transparencia",
      "Generosidad",
      "Trabajo en equipo",
      "Innovación",
      "Dinamismo",
      "Apertura al cambio",
      "Tolerancia al fallo",
      "Confianza",
      "Compromiso",
      "Empatía",
      "Integridad",
    ],
    ArquetiposPuntuación: {
      People: 25,
      Innovation: 15,
      Norms: 10,
      Goals: 10,
      Digital: 25,
      ESG: 15,
    },
  },
  34: {
    Nombre: "Unicaja",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQFePoJnvgMkdA/company-logo_200_200/company-logo_200_200/0/1710141926093/unicaja_logo?e=1733961600&v=beta&t=s5dAuAVvFNdVYKxbzw-2-itVaeWtPqFKSvuZ_bKw5co",
    Industria: "Banca",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Bienestar",
      "Confianza",
      "Cercanía",
      "Generosidad",
      "Excelencia",
      "Responsabilidad",
      "Eficiencia",
      "Trabajo en equipo",
      "Cooperación",
      "Adaptación al cambio",
      "Orientación al aprendizaje",
    ],
    ArquetiposPuntuación: {
      People: 30,
      Innovation: 10,
      Norms: 15,
      Goals: 10,
      Digital: 25,
      ESG: 10,
    },
  },
  35: {
    Nombre: "Mercadona",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQHPPcaMiAYj3A/company-logo_200_200/company-logo_200_200/0/1708933056375/mercadona_logo?e=1733961600&v=beta&t=ASyxd9kaxk-2mJQfMGjFnCfwePFjnEtJ8uuxlKVOVOE",
    Industria: "Retail",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Excelencia",
      "Eficiencia",
      "Cercanía",
      "Empatía",
      "Trabajo en equipo",
      "Compromiso",
      "Innovación",
      "Dinamismo",
      "Sostenibilidad",
      "Responsabilidad",
    ],
    ArquetiposPuntuación: {
      People: 25,
      Innovation: 20,
      Norms: 25,
      Goals: 10,
      Digital: 10,
      ESG: 10,
    },
  },
  36: {
    Nombre: "Vueling",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQHEBZVvJAVn8w/company-logo_200_200/company-logo_200_200/0/1722848638174/vueling_airlines_logo?e=1733961600&v=beta&t=Qdcihr0boRVSCE16zFCqwQ8r8uV0ZD_rtr5634OD7EU",
    Industria: "Aeronáutica",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Orientación a resultados",
      "Excelencia",
      "Innovación",
      "Sostenibilidad",
      "Responsabilidad",
      "Bienestar",
      "Eficiencia",
      "Empatía",
      "Cooperación",
      "Diversidad",
      "Respeto",
    ],
    ArquetiposPuntuación: {
      People: 10,
      Innovation: 10,
      Norms: 15,
      Goals: 20,
      Digital: 15,
      ESG: 30,
    },
  },
  37: {
    Nombre: "DCOOP",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C560BAQGJcW2LwGvr0A/company-logo_200_200/company-logo_200_200/0/1634888542217/dcoop_logo?e=1733961600&v=beta&t=nxifPrh7QLQ3vqla22ocwgDHAFGyRwwaiNicBAxp1v8",
    Industria: "Agroalimentario",
    País: "España",
    Verificada: true,
    ValorCFAM: [
      "Sostenibilidad",
      "Integridad",
      "Respeto",
      "Bienestar",
      "Innovación",
      "Excelencia",
    ],
    ArquetiposPuntuación: {
      People: 16.2,
      Innovation: 15.7,
      Norms: 19.3,
      Goals: 19.8,
      Digital: 14.9,
      ESG: 14.1,
    },
  },
  38: {
    Nombre: "Emasesa",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQGnjC2V-8_bow/company-logo_200_200/company-logo_200_200/0/1710148613167/emasesa_logo?e=1733961600&v=beta&t=OdLvnYLThvtlA0FHbrZqdYtZf2TTB2xTWEZSQLqr8N4",
    Industria: "Servicios Públicos",
    País: "España",
    Verificada: true,
    ValorCFAM: [
      "Compromiso",
      "Responsabilidad",
      "Integridad",
      "Generosidad",
      "Colaboración",
      "Sostenibilidad",
      "Confianza",
      "Innovación",
    ],
    ArquetiposPuntuación: {
      People: 15.5,
      Innovation: 14.3,
      Norms: 21.6,
      Goals: 17.5,
      Digital: 15.8,
      ESG: 15.3,
    },
  },
  39: {
    Nombre: "Cuatrogasa",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C560BAQEil-nMj4EBSQ/company-logo_200_200/company-logo_200_200/0/1630608895699?e=1733961600&v=beta&t=lOQWnG8aqP2AdzXODBTRSgVaBBmWx_GTNeeJsNTzeYs",
    Industria: "Manufacturera",
    País: "España",
    Verificada: true,
    ValorCFAM: [
      "Generosidad",
      "Compromiso",
      "Trabajo en equipo",
      "Ambición",
      "Excelencia",
      "Cercanía",
      "Eficiencia",
    ],
    ArquetiposPuntuación: {
      People: 20.3,
      Innovation: 15.3,
      Norms: 15.3,
      Goals: 19,
      Digital: 16.3,
      ESG: 13.8,
    },
  },
  40: {
    Nombre: "Helvetia",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C4D0BAQFNv2bzwyC3-g/company-logo_200_200/company-logo_200_200/0/1631335890768?e=1733961600&v=beta&t=nR9bkOizKXu31AP_BQu__fcIpYxGQRFUGigN9WNUHC8",
    Industria: "Seguros",
    País: "España",
    Verificada: false,
    ValorCFAM: ["Confianza", "Dinamismo", "Ambición", "Atrevimiento"],
    ArquetiposPuntuación: {
      People: 25,
      Innovation: 30,
      Norms: 10,
      Goals: 15,
      Digital: 10,
      ESG: 10,
    },
  },
  41: {
    Nombre: "Hero",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQGaRfOaaP2u1g/company-logo_200_200/company-logo_200_200/0/1724330969390/herospain_logo?e=1733961600&v=beta&t=CcKtj1mZQIIYxtJkBCMdQ-durZWQM_RxeoN2eA_oHKE",
    Industria: "Manufacturera",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Innovación",
      "Creatividad",
      "Liderazgo",
      "Trabajo en equipo",
      "Generosidad",
      "Bienestar",
      "Responsabilidad",
      "Compromiso",
    ],
    ArquetiposPuntuación: {
      People: 25,
      Innovation: 25,
      Norms: 15,
      Goals: 15,
      Digital: 10,
      ESG: 10,
    },
  },
  42: {
    Nombre: "Sanitas",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C4D0BAQFb7K3B-oAyuQ/company-logo_200_200/company-logo_200_200/0/1644225315834/sanitas_logo?e=1733961600&v=beta&t=4l7yduih00nhEoYexd8zCmqVWnI94qEmePi-yHqzkJU",
    Industria: "Seguros",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Atrevimiento",
      "Respeto",
      "Responsabilidad",
      "Sostenibilidad",
      "Bienestar",
      "Cercanía",
    ],
    ArquetiposPuntuación: {
      People: 15,
      Innovation: 15,
      Norms: 15,
      Goals: 15,
      Digital: 15,
      ESG: 25,
    },
  },
  43: {
    Nombre: "Hijos de Rivera",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C4D0BAQFWcUMwAgtccQ/company-logo_200_200/company-logo_200_200/0/1669654427471/corporacionhijosderivera_logo?e=1733961600&v=beta&t=DoToxYzHBKqJt_xl_IqCDOOAe0rtEXIAxyd-qAlCikE",
    Industria: "Manufacturera",
    País: "España",
    Verificada: true,
    ValorCFAM: [
      "Excelencia",
      "Sostenibilidad",
      "Cercanía",
      "Innovación",
      "Creatividad",
    ],
    ArquetiposPuntuación: {
      People: 16.9,
      Innovation: 18.4,
      Norms: 14.9,
      Goals: 21.6,
      Digital: 14.7,
      ESG: 13.5,
    },
  },
  44: {
    Nombre: "MP Ascensores",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C4E0BAQHd9Qn7vfTc4Q/company-logo_200_200/company-logo_200_200/0/1630565346304/mp_ascensores_ascenseurs_aufzuege____lifts_logo?e=1733961600&v=beta&t=MB-L338quD77iZfnLId00KFyw0Z1I26wqXrtG5dcgQ8",
    Industria: "Manufacturera",
    País: "España",
    Verificada: true,
    ValorCFAM: [
      "Cercanía",
      "Integridad",
      "Estabilidad",
      "Confianza",
      "Innovación",
      "Dinamismo",
      "Excelencia",
    ],
    ArquetiposPuntuación: {
      People: 17.7,
      Innovation: 14.9,
      Norms: 18.1,
      Goals: 19.2,
      Digital: 15.9,
      ESG: 14.2,
    },
  },
  45: {
    Nombre: "Dacsa",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C560BAQF-LLtQ2uP7jg/company-logo_200_200/company-logo_200_200/0/1631406012929?e=1733961600&v=beta&t=CkLt60TtQIlPA515f9T_HBACgZzDTD6xI2s1HQhIv8I",
    Industria: "Agroalimentario",
    País: "España",
    Verificada: true,
    ValorCFAM: [
      "Compromiso",
      "Innovación",
      "Cercanía",
      "Generosidad",
      "Trabajo en equipo",
      "Responsabilidad",
    ],
    ArquetiposPuntuación: {},
  },
  46: {
    Nombre: "OK Mobility",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/C4E0BAQFaU70B_YZpsw/company-logo_200_200/company-logo_200_200/0/1657009680818/ok_mobility_mobility_services_logo?e=1733961600&v=beta&t=MhytR4Kbx88JxUT30_7p_5aJ-ZGkIJ3Luafn8akGt8E",
    Industria: "Transporte",
    País: "España",
    Verificada: true,
    ValorCFAM: [
      "Trabajo en equipo",
      "Sostenibilidad",
      "Bienestar",
      "Excelencia",
      "Competitividad",
      "Ambición",
      "Perseverancia",
    ],
    ArquetiposPuntuación: {
      People: 15.2,
      Innovation: 18.8,
      Norms: 15.6,
      Goals: 21.2,
      Digital: 16.1,
      ESG: 13.1,
    },
  },
  47: {
    Nombre: "Iturri",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQEkFiEe2Qt6hg/company-logo_200_200/company-logo_200_200/0/1684837477966/iturri_logo?e=1733961600&v=beta&t=tWW9gbOdDUFuS3SXdZdcI2MmcjkkrYhgJpXar5mHB8Q",
    Industria: "Manufacturera",
    País: "España",
    Verificada: true,
    ValorCFAM: [
      "Empatía",
      "Trabajo en equipo",
      "Colaboración",
      "Compromiso",
      "Integridad",
      "Innovación",
    ],
    ArquetiposPuntuación: {
      People: 20.8,
      Innovation: 14.9,
      Norms: 14.4,
      Goals: 17.8,
      Digital: 16.4,
      ESG: 15.7,
    },
  },
  48: {
    Nombre: "Grupo MAS",
    URL_Logo:
      "https://media.licdn.com/dms/image/v2/D4D0BAQGUJ12Ekvzcsg/company-logo_200_200/company-logo_200_200/0/1704803879402/somosgrupomas_logo?e=1733961600&v=beta&t=LityH5cEt1bI3g7_5efZH4MGsVNZ-qTvjVnTGToBZl8",
    Industria: "Gran Consumo",
    País: "España",
    Verificada: false,
    ValorCFAM: [
      "Excelencia",
      "Competitividad",
      "Dinamismo",
      "Compromiso",
      "Trabajo en equipo",
      "Generosidad",
      "Innovación",
      "Apertura al cambio",
    ],
    ArquetiposPuntuación: {
      People: 25,
      Innovation: 20,
      Norms: 10,
      Goals: 20,
      Digital: 15,
      ESG: 10,
    },
  },
};
