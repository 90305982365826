export default {
  setOrderedHeaders({ commit }, headers) {
    commit("updateOrderedHeaders", headers);
  },
  updateCurrentStep({ commit }, step) {
    commit("setCurrentStep", step);
  },
  resetHeaders({ commit }) {
    commit("resetOrderedHeaders");
  },
};
