<script setup>
import horizontalLinesImage from "@/assets/report-images/horizontal-lines.png";
import CultureProfileChart from "@/components/CultureEvalComponents/ProfilesEvaluation/CandidateReport/CultureProfileChart.vue";
import CultureReportArchetypeAffinityTable from "@/components/CultureEvalComponents/ProfilesEvaluation/CandidateReport/CultureReportArchetypeAffinityTable.vue";
import DictionaryForCultureReport from "@/globals/DictionaryForCultureReport"; // Importar el diccionario
import { computed, defineProps } from "vue";
import { useStore } from "vuex"; // Importar useStore
import CultureReportPredominantValues from "./CultureReportPredominantValues.vue";

const props = defineProps({
  nameTitle: String,
  predominantCultures: {
    type: Array,
  },
  predominance: {
    type: Array,
  },
  mediaValues: {
    type: Array,
  },
  colors: {
    type: Array,
    default: () => ["#cccccc", "#999999"], // Colores por defecto
  },
});

const store = useStore(); // Instancia de la tienda Vuex

const PageTitle = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForCultureReport[currentLanguage][3];
});

const footerText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForCultureReport[currentLanguage][1];
});
</script>

<template>
  <!-- PAGE 1 -->
  <div id="executiveSummary" class="page">
    <div class="page-body">
      <img :src="horizontalLinesImage" class="page3-horizontal-lines" />

      <div>
        <div class="title">{{ PageTitle }}:</div>
        <div class="subtitle mt-2">
          <strong>{{ props.nameTitle }}</strong>
        </div>
      </div>

      <div class="charts">
        <div class="chart">
          <CultureProfileChart
            width="270px"
            height="270px"
            :series="props.predominantCultures"
            :colors="props.colors"
          />
        </div>

        <CultureReportArchetypeAffinityTable
          :predominance="props.predominance"
          width="270px"
          height="270px"
          class="mt-9"
        />
      </div>
      <h4>
        Aquí va la tabla de mentalidades predominantes 1 y 2 como la del informe
        de candidato (Falta mandarlo desde el Back)
      </h4>
      <div class="page-footer">
        {{ footerText }}
      </div>
    </div>
  </div>

  <!-- PAGE 2 -->
  <div class="page">
    <div class="page-body">
      <img :src="horizontalLinesImage" class="page3-horizontal-lines" />

      <div>
        <div>{{ PageTitle }}:</div>
        <div class="subtitle mt-2">
          <strong>{{ props.nameTitle }}</strong>
        </div>
      </div>

      <CultureReportPredominantValues
        :mediaValues="props.mediaValues"
        :colors="props.colors"
      />

      <div class="page-footer-different">
        {{ footerText }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page3-horizontal-lines {
  width: 312px;
  position: absolute;
  margin-top: 0px;
  right: 0px;
}
.page-footer-different {
  font-size: calc(6.5px * 1.333);
  font-style: italic;
  width: 999px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  color: #808080;
  border-top: 1px solid #808080;

  text-align: left;
  height: 110px;
}

.charts {
  display: flex;
  gap: 110px;
  justify-content: center;
  padding-top: 2%;

  .chart {
    min-width: 90px;
  }
}
</style>
