export const valueByArchetype = new Map([
    ["People", ["Cercanía", "Generosidad", "Trabajo en equipo", "Lealtad", "Confianza", "Compromiso"]],
    ["Innovation", ["Dinamismo", "Creatividad", "Autonomía", "Innovación", "Tolerancia al riesgo", "Atrevimiento"]],
    ["Goals", ["Orientación a resultados", "Excelencia", "Competitividad", "Pragmatismo", "Ambición", "Liderazgo"]],
    ["Norms", ["Formalidad", "Coordinación", "Estabilidad", "Responsabilidad", "Perseverancia", "Eficiencia"]],
    ["Digital", ["Apertura al cambio", "Enfoque analítico", "Cooperación", "Tolerancia al fallo", "Orientación al aprendizaje", "Empatía"]],
    ["ESG", ["Bienestar", "Integridad", "Transparencia", "Respeto", "Diversidad", "Sostenibilidad"]],
]);

const invertMap = (originalMap) => {
    const nuevoMap = new Map();

    originalMap.forEach((valores, llave) => {
        valores.forEach(valor => {
            nuevoMap.set(valor, llave);
        });
    });

    return nuevoMap;
};

export const archetypeByValue = invertMap(valueByArchetype)