<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { defineProps } from "vue";
import DictionaryForCultureReport from "@/globals/DictionaryForCultureReport";
import greenCircleImage from "@/assets/report-images/green-circle.png";
import diagonalLinesImage from "@/assets/report-images/diagonal-lines.png";
import dotsImage from "@/assets/report-images/dots.png";
import cfamLogoImage from "@/assets/report-images/cfam-logo-horizontal.png";
import colaboratosLogoImage from "@/assets/report-images/collaborator-logos.png";

import linesImage from "@/assets/report-images/lines.png";

const store = useStore();

defineProps({
  CultureReportData: Object,
});

const ReportTitle = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForCultureReport[currentLanguage][0];
});
/* const footerText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForCultureReport[currentLanguage][1];
}); */
const DateText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForCultureReport[currentLanguage][2];
});
</script>

<template>
  <div id="coverPage" class="page">
    <div class="page-body">
      <img :src="greenCircleImage" class="page1-green-circle" />
      <img :src="diagonalLinesImage" class="page1-diagonal-lines" />
      <img :src="dotsImage" class="page1-dots" />

      <img :src="cfamLogoImage" class="page1-cfam-logo" />
      <img :src="linesImage" class="page1-lines-logo" />
      <div class="cover-data">
        <div class="title">{{ ReportTitle }}</div>
        <div class="green-field mt-20">{{ CultureReportData.name }}</div>
        <strong>{{ DateText }}:</strong>
        {{ new Date().toLocaleDateString("es-ES") }}
      </div>

      <div class="cover-data2">
        <div class="small-dark-green-field">
          {{ "Ficha técnica:" }}
          <br />
          <strong>ID:</strong> {{ CultureReportData.token }}
        </div>
        <div class="small-dark-green-field">
          <strong>Cliente:</strong> {{ CultureReportData.token }}
        </div>
        <div class="small-dark-green-field">
          <strong>Número de respuestas:</strong> {{ CultureReportData.filas }}
        </div>
        <div class="small-dark-green-field">
          <strong>Corte:</strong> {{ CultureReportData.token }}
        </div>
      </div>

      <img :src="colaboratosLogoImage" class="page1-cfam-logo-colaborators" />
    </div>
  </div>
</template>

<style scoped>
.page1-green-circle {
  display: block;
  width: 240px;
  position: absolute;
  left: 32px;
}

.page1-diagonal-lines {
  display: block;
  width: 168px;
  position: absolute;
  margin-top: 32px;
  right: 32px;
}

.page1-dots {
  display: block;
  width: 263px;
  position: absolute;
  margin-top: 566px;
  left: 108px;
}

.page1-cfam-logo {
  display: block;
  width: 399px;
  position: absolute;
  margin-top: 206px;
  right: 605px;
}
.page1-cfam-logo-colaborators {
  display: block;
  width: 539px;
  position: absolute;
  margin-top: 706px;
  right: 305px;
}

.page1-lines-logo {
  display: block;
  width: 80px;
  position: absolute;
  margin-top: 254px;
}

.cover-data {
  position: absolute;
  left: 670px;
  margin-top: 235px;
  text-align: end;
}

.cover-data2 {
  position: absolute;
  left: 670px;
  margin-top: 435px;
  text-align: left;
}

.cover-date {
  position: absolute;
  right: 116px;
  margin-top: 913px;
  text-align: right;
}

.cover-green-rect {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 65px;
  margin-top: 860px;
  background-color: #2eb6b9;
}

.cover-dark-green-rect {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 54px;
  margin-top: 818px;
  background-color: #33495f;
}
</style>
