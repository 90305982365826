import { createRouter, createWebHistory } from "vue-router";
import EvaluationView from "../views/EvaluationView.vue";
import MinzerPdfView from "../views/MinzerPdf.vue";
import CulturalEvaluationView from "../views/CulturalEvaluationView.vue";
const routes = [
  {
    path: "/evaluation/:candidateToken/:language",
    name: "evaluation",
    component: EvaluationView,
  },
  {
    path: "/minzer/:companyid",
    name: "minzer",
    component: MinzerPdfView,
  },
  {
    path: "/cultural-evaluation/:projectToken/:language",
    name: "cultural-evaluation",
    component: CulturalEvaluationView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
