<template>
  <v-app id="app">
    <!--Go to LoginLayaout-->
    <router-view />
  </v-app>
</template>

<script>
import Dictionary from "@/globals/Dictionary";
import Archetypes from "@/globals/Archetypes";
import EthicArchetypes from "@/globals/EthicArchetypes";
import LeaderValues from "./globals/LeaderValues";
import LeaderValues2 from "./globals/LeaderValues2";
export default {
  name: "App",

  components: {},

  data: () => ({
    //
  }),

  methods: {
    select_leng() {
      let current_leng = JSON.stringify(Dictionary.spanish);
      sessionStorage.setItem("texts", current_leng);
      let archetypes = JSON.stringify(Archetypes.spanish);
      let ethic_archetypes = JSON.stringify(EthicArchetypes.spanish);
      let leader_values = JSON.stringify(LeaderValues.spanish);
      let leader_values2 = JSON.stringify(LeaderValues2.spanish);
      sessionStorage.setItem("archetypes", archetypes);
      sessionStorage.setItem("ethic_archetypes", ethic_archetypes);
      sessionStorage.setItem("leader_values", leader_values);
      sessionStorage.setItem("leader_values2", leader_values2);

      // switch (window.navigator.language) {
      //   case "es": {
      //     let current_leng = JSON.stringify( Dictionary.spanish);
      //     sessionStorage.setItem('texts', current_leng);
      //     let archetypes = JSON.stringify( Archetypes.spanish);
      //     sessionStorage.setItem('archetypes', archetypes);
      //     break;
      //   }
      //   case "es-ES": {
      //     let current_leng = JSON.stringify( Dictionary.spanish);
      //     sessionStorage.setItem('texts', current_leng);
      //     break;
      //   }
      //   case "en-US":{
      //     let current_leng = JSON.stringify( Dictionary.english);
      //     sessionStorage.setItem('texts', current_leng);
      //     break;
      //   }
      //   case "en":{
      //     let current_leng = JSON.stringify( Dictionary.english);
      //     sessionStorage.setItem('texts', current_leng);
      //     break;
      //   }
      //   default : {
      //     let current_leng = JSON.stringify( Dictionary.english);
      //     sessionStorage.setItem('texts', current_leng);
      //   }
      // }
    },
  },

  created() {
    this.select_leng();
  },
};
</script>

<style>
input {
  color: #585858 !important;
}
</style>
