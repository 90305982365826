<script setup>
import { archetypeByValue } from "@/utils/burnData";
import { defineProps, computed } from "vue";

// Definir propiedades del componente
const props = defineProps({
  series: {
    type: Array,
    required: true,
    default: () => [],
  },
  height: {
    type: String,
    default: "590px",
  },
  width: {
    type: String,
    default: "100%",
  },
  colors: {
    type: Array,
    default: () => ["#33B0B3", "#33B0B3"],
  },
});

const array1to36 = Array.from({ length: 36 }, (_, index) => (index + 1).toString());

// Configuración del gráfico
const chartOptions = computed(() => ({
  chart: {
    height: parseInt(props.height),
    type: "bar",
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,

    },
  },
  xaxis: {
    categories:array1to36.map((i) => i),
    labels: {
      show: true,
      style: {
        colors: Array(props.series.length).fill("#34495e"),
        fontSize: "12px",
        fontFamily: "Arial",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: true,
    },
  },
  yaxis: {
    show: true,
  },
  grid: {
    show: true,
  },
  dataLabels: {
    enabled: true,
    style: {
      colors: ["#34495e"],
      fontSize: "12px",
      fontWeight: "bold",
      position: "start",
    },
    // Función para formatear las etiquetas de datos
    formatter: (val, { dataPointIndex }) => {
      const title = props.series[dataPointIndex]?.title || "";
      return `${title} ( ${archetypeByValue.get(title)} )`;
    },
    offsetX: 0,
    offsetY: 0,
    align: 'left',
  },
  legend: {
    show: true,
  },
  stroke: {
    width: 1,
    colors: props.colors,
  },
  fill: {
    colors: props.colors,
  },
}));

// Preparar los datos para la serie
const seriesData = computed(() => [
  {
    name: "Media Values",
    data: props.series.map((item) => item.value),
  },
]);
</script>

<template>
  <apexchart
    type="bar"
    :width="width"
    :height="height"
    :options="chartOptions"
    :series="seriesData"
  ></apexchart>
</template>

<style scoped>
/* Estilos aquí, si es necesario */
</style>