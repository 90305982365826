export default {
  es: [
    "Diagnóstico CFAM® de Cultura y Mentalidad de:", //0
    "Todos los derechos reservados. El Cultural Fit Assessment Method (CFAM©) es propiedad de Cultural Fit Solutions S.L. Queda prohibida la reproducción total o parcial del material protegido por estos derechos de propiedad intelectual, su uso en cualquier forma, o por cualquier medio, ya sea electrónico o mecánico, incluyendo fotocopiado, grabación, transmisión o cualquier sistema de almacenamiento y recuperación de información, sin el permiso por escrito de Cultural Fit Solutions S.L.", //1
    "Fecha", //2
    "Culturas predominantes en la organización", //3
  ],

  en: [
    "Culture & Mentality report of", //0
    "All rights reserved. The Cultural Fit Assessment Method (CFAM®) is property of Cultural Fit Solutions S.L. The total or partial reproduction of the material protected by these intellectual property, its use in any form, or by any means, whether electronic or mechanical, including photocopying, recording, transmission or any information storage and retrieval system or otherwise, is prohibited without the written permission of Cultural Fit Solutions S.L.", //1
    "Date", //2
    "Predominant Organtization's Culture", //3
  ],
};
