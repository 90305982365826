export default {
  //super User
  super_user: {
    user: "beta@example.com",
    password: "123",
  },

  //Signin Data Form
  //Registered user waiting to confirm pin
  registered_user: {
    email: "",
    full_name: "",
  },

  //Login Data Form
  login_form: {
    email: "",
    password: "",
  },

  //Show Email Validation Dialog to Register
  registration_email_validation_dialog: false,

  //Show Email Validation Dialog to Login
  login_email_validation_dialog: false,

  user_info: {
    id: "",
    full_name: "DEAFULT USER",
    company_name: "DEFAULT COMPANY",
    position: "",
    email: "beta@example.com",
    phone: "",
    password: "123",
    tokens: {
      tokenDC: "",
      tokenPE: "",
    },
    authorisation_token: "",
    countDC: 0,
    countPE: 0,
    urlTest: "",
  },

  //Dashboard View
  dashboard_step: 2,

  test_dialog: false,

  //Data from API

  //Org SpiderChart Data
  org_serie: [],

  org_chart_options: {
    chart: {
      type: "radar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [],
      labels: {
        show: true,
        style: {
          colors: [
            "#34495e",
            "#34495e",
            "#34495e",
            "#34495e",
            "#34495e",
            "#34495e",
          ],
          fontSize: "11px",
          fontFamily: "Arial",
        },
      },
    },
    legend: {
      show: "true",
      position: "top",
    },

    yaxis: {
      show: false,
    },
    stroke: {
      width: 1,
      colors: ["#00C3C0"],
    },
    markers: {
      size: 0,
    },
    fill: {
      colors: ["#00C3C0"],
    },
  },

  //Org-Team SpiderChart Team Data
  team_serie: [],

  org_team_chart_options: {
    chart: {
      height: 350,
      type: "radar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [],
      labels: {
        show: true,
        style: {
          colors: [
            "#34495e",
            "#34495e",
            "#34495e",
            "#34495e",
            "#34495e",
            "#34495e",
          ],
          fontSize: "11px",
          fontFamily: "Arial",
        },
      },
    },

    yaxis: {
      show: false,
    },
    legend: {
      show: true,
      offsetY: -24,
      labels: {
        colors: "#34495e",
      },
      fontWeight: 600,
      fontSize: "9px",
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: ["#00C3C0", "#34495e"],
        radius: 0,
        onClick: undefined,
      },
    },
    stroke: {
      width: 1,
      colors: ["#00C3C0", "#34495e"],
    },
    markers: {
      size: 0,
    },
    fill: {
      colors: ["#00C3C0", "#34495e"],
    },
  },

  //Charts Categories
  archetypes_titles: [],

  //Charts Categories Descriptions
  archetypes_text: [],

  //Archetype Predominance
  archetype_predominance: [],

  //Received Answers
  summary: "N/D",

  //Link to copy
  internal_link: "",

  //Speedometer Percent
  speedo_percent: 0,

  //Profile Evaluation -> Candidates General View

  candidates: [],

  candidates_to_show: [],

  param_candidates: [],
  param_candidates_to_show: [],
  totalParametization: [],

  candidates_to_show_aux: [],

  external_link: "",

  //Profiles Evaluation View

  detailed_view_step: 0,

  //Profiles Evaluation View -> Candidate Detailed View
  detailed_view_options_step: 1,

  //Profile Evaluation -> Candidates General View -> Culture Profile

  //Archetype Predominance

  //Profiles Evaluation View -> Candidate Detailed View -> Culture Profile
  //Button Toggle is always selected on Cultural Profile
  toggle: "cultural_profile_btn",

  prevailing_principles: [
    {
      value: "Lealtad",
      text: "",
    },
    {
      value: "Compromiso",
      text: "",
    },
    {
      value: "Trabajo en equipo",
      text: "",
    },
    {
      value: "Dinamismo",
      text: "",
    },
    {
      value: "Perseverancia",
      text: "",
    },
    {
      value: "Confianza",
      text: "",
    },
  ],

  search_table: "",

  //Profiles Evaluation -> Candidates General View -> Candidates Table

  table_desktop_headers: [
    "",
    "Email/Nombre de Usuario",
    "Nombre Completo",
    "Género",
    "Fit Org. Individual",
    "Fit Org. Desarrollo",
    "ICDE",
    "Arq. Individual Dominante",
    "Cultura Org. Deseada",
    "Etiquetas",
    "Acciones",
  ],

  table_mobile_headers: [
    "Email/Nombre de Usuario",
    "Nombre Completo",
    "Fit Persona-Organización(1)",
    "Fit Deseado-Organización(2)",
    "Arq. Individual Dominante",
    "Cultura Org. Deseada",
    "Etiquetas",
    "Acciones",
  ],

  //Selected Candidate to see details
  selected_candidate: {},

  //Tagss List to Label Menu in Candidate General View
  tags_list: [
    "Etiqueta1",
    "Etiqueta2",
    "Etiqueta3",
    "Etiqueta4",
    "Etiqueta5",
    "Etiqueta6",
    "Etiqueta7",
    "Etiqueta8",
  ],

  filter_tags_value: [],

  candidates_length: 16,

  selectedParametrization: {
    id: null,
    name: "",
    data: "",
  },

  //Label Menu
  newtag_dialog: false,
  newlabel_alert: false,

  //Search Text Field
  search: "",

  //Speedometer percent
  percent: 95,

  //Candidate Detailed View
  cultureProfileData: {
    series: [
      {
        name: "Predominance Values",
        data: [],
      },
    ],

    predominance: [],

    arch_titles: [],

    arch_texts: [],

    principles_titles: [],
    principles_texts: [],
    arch_titles_PRED: [],
  },

  engagementData: {
    series_1: [
      {
        name: "Cultura actual",
        data: [0, 0, 0, 0, 0, 0],
      },
      {
        name: "Mentalidad de la persona",
        data: [0, 0, 0, 0, 0, 0],
      },
    ],

    series_2: [
      {
        name: "Cultura deseada",
        data: [0, 0, 0, 0, 0, 0],
      },
      {
        name: "Mentalidad de la persona",
        data: [0, 0, 0, 0, 0, 0],
      },
    ],

    arch_titles: [],
    /*  arch_titles_PRED: [], */

    per_org_fit: 0,

    des_org_fit: 0,
  },
  mindsetIdealFitData: {
    series_1: [
      {
        name: "Mentalidad Ideal",
        data: [0, 0, 0, 0, 0, 0],
      },
      {
        name: "Mentalidad de la persona",
        data: [0, 0, 0, 0, 0, 0],
      },
    ],

    series_2: [
      {
        name: "Mentalidad Ideal",
        data: [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
      },
      {
        name: "Mentalidad de la persona",
        data: [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
      },
    ],

    arch_titles: [],
    /*  arch_titles_PRED: [], */

    mindsetFitPercent: 0,
    paramName: "",
  },
  ethicalData: {
    integrityIndicator: 0,
    candidateIntegritySeries: [
      {
        name: "Cultura de Integridad en la Persona",
        data: [1, 2, 3, 4, 5, 6],
      },
    ],
  },
  integrityValues: [],
  integrityValBehaviour: [],
  integrityProb: [],

  fitAreasData: [],
  predValuesData: {},
  valuesTitle: [],
  valuesTexts: [],
  valuesCompetences: [],

  language: "es", // idioma inicial

  leaderData: {
    series_3: [
      {
        name: "Liderazgo transformacional",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10],
      },
    ],
    series_4: [
      {
        name: "Liderazgo transaccional",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10],
      },
    ],

    transaccionalRAW: {},

    transformacionalPoints: 0,
    transaccionalPoints: 0,
    cuadrante1d4: "",
    cuadranteText: "",
    projectName: "",
    CandTkn: "",
  },
  originalHeaders: ["People", "Innovation", "Goals", "Norms", "Digital", "ESG"],
  orderedHeaders: [],
};
